<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #title>Servidores</template>
        <template #content>
          <DataTable
            stripedRows
            :value="contracheques"
            :loading="loading"
            :paginator="true"
            :filters.sync="filtros"
            :globalFilterFields="['orgao', 'dataInclusao', 'Periodo']"
            filterDisplay="menu"
            :rows="10"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="rowsPerPageOptions"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} contracheques"
            responsiveLayout="scroll">
            <template #empty> Nenhuma matrícula encontrada. </template>
            <template #loading> Carregando. Por favor aguarde. </template>
            <template #header>
              <span class="mt-2 mr-2 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filtros['global'].value"
                  placeholder="Pesquisar"
                  style="width: 100%" />
              </span>
              <Button
                icon="pi pi-filter-slash"
                label="Limpar"
                class="mt-2 mr-2 p-button-outlined"
                @click="limparFiltro" />
            </template>
            <Column field="orgao" header="Orgão" :sortable="true"></Column>
            <Column field="dataInclusao" header="Data de Inclusão">
              <template #body="{ data }">
                <div>
                  {{ data.dataInclusao }}
                </div>
              </template>
            </Column>
            <Column field="periodo" header="Periodo"></Column>
            <Column field="parcela" header="Parcela" :sortable="true"></Column>
            <Column header="Visualizar">
              <template #body="{ data }">
                <div>
                  <Button
                    v-tooltip.left="'Visualizar Contracheque'"
                    :placeholder="'Visualizar Contracheque'"
                    icon="pi pi-file-pdf"
                    class="p-button-rounded p-button-danger mr-2"
                    @click="gerarContracheque(data)" />
                </div>
              </template>
            </Column>
          </DataTable>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api/'
import ContrachequeService from '@/service/ContrachequeService'
import MatriculaService from '@/service/MatriculaService.js'

export default {
  data() {
    return {
      matricula: this.$route.params.matricula,
      cpf: this.$route.params.cpf,
      loading: false,
      totalRecords: 0,
      contracheques: [],
      filtros: {},
    }
  },

  computed: {
    rowsPerPageOptions() {
      return [10, 25, 50]
    },
  },

  created() {
    this.matriculaService = new MatriculaService(this.$http)
    this.contrachequeService = new ContrachequeService(this.$http)
    this.gerarContrachequePDF = false
    this.initFiltros()
    this.gerarRelatorio = false
  },

  mounted() {
    this.loading = true
    this.carregarDadosContracheque(this.cpf, this.matricula)
  },

  methods: {
    carregarDadosContracheque(cpf, matricula) {
      this.contrachequeService
        .getListaContrachequeDoServidor(cpf, matricula)
        .then((res) => {
          this.contracheques = res
        })
        .catch((err) => {
          this.showToastError(err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    gerarContracheque(data) {
      this.$router.push({
        name: 'contrachequeDetalhes',
        params: { id: data.id },
      })
    },

    gerarDocumentoPDF() {
      this.gerarContrachequePDF = true
      this.$refs.contrachequeTemplate.gerarPDF()
      this.gerarContrachequePDF = false
    },

    carregarMatriculas() {
      this.matriculaService
        .getMatricula(this.idMatricula)
        .then((res) => {
          this.matricula = res
          this.carregarDadosContracheque(this.matricula.servidor.cpf)
        })
        .catch((err) => {
          this.showToastError(err)
        })
    },
    limpar() {
      this.loading = false
    },

    limparFiltro() {
      this.initFiltros()
    },

    initFiltros() {
      this.filtros = {
        global: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.CONTAINS },
            { value: null, matchMode: FilterMatchMode.EQUALS },
          ],
        },
        dataInclusao: {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        periodo: {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        parcela: {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
      }
    },

    tratarDataInclusao(dataInclusao) {
      const partes = dataInclusao.split(' às ')
      const dataString = partes[0]
      const horaString = partes[1]

      const [dia, mes, ano] = dataString.split('/')

      const [horas, minutos, segundos] = horaString.split(':')

      const data = new Date(ano, mes - 1, dia, horas, minutos, segundos)

      return data
    },

    converterDataParaDateStylePadrao(data) {
      // const day = data.toLocaleString('default', { day: '2-digit' })
      // const month = data.toLocaleString('default', { month: '2-digit' })
      // const year = data.getFullYear()
      // const hour = data.toLocaleTimeString('en-GB')

      // return `${day}/${month}/${year} às ${hour}`
      return data
    },

    showToastError(err) {
      const mensagem = err.response
        ? err.response.data.message
        : 'Erro de conexão com a aplicação.'
      this.limpar()
      this.$toast.add({
        severity: 'error',
        summary: mensagem,
        life: 10000,
      })
    },
  },
}
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
</style>
